import React, { Component } from 'react';

import SectionHeader from '../layout/section-header';



const cattitle = <span className="category wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">My Services</span>;
const title = <h2 className="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">We Have Done Lot's of <span>My Best Services</span> Lets Check Some of Them</h2>


const ServicesList = [
    {
        title: 'PSD Template Design',
		description: 'Mnique deplo scalable catals forin Monec formu was unique',
		imgUrl: "assets/images/service/01.png",
		imgAlt: 'service',
    },
    {
        title: 'Mobile Apps Design',
        description: 'Mnique deplo scalable catals forin Monec formu was unique',
		imgUrl: "assets/images/service/02.png",
        imgAlt: 'service',
    },
    {
        title: 'SEO Optimization',
        description: 'Mnique deplo scalable catals forin Monec formu was unique',
		imgUrl: "assets/images/service/03.png",
        imgAlt: 'service',
    },
    {
        title: 'Web Development',
        description: 'Mnique deplo scalable catals forin Monec formu was unique',
		imgUrl: "assets/images/service/04.png",
		imgAlt: 'service',
    },
]




class Services extends Component {
    render() {
        return (
            <section className="services padding-tb text-start" id='Services'>
			<div className="container">
				<div className="section-header">
					<div className="title">
						<SectionHeader
							cattitle={ cattitle } 
							title={ title }
						/>
					</div>
				</div>
				<div className="section-wrapper">
					{ServicesList.map( (val , i) => (
						<div className="service-item wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s" key={i}>
							<div className="service-inner">
								<div className="service-content">
									<h3>{val.title}</h3>
									<p>{val.description}</p>
									<ul>
										<li>Motion Graphic Design</li>
										<li>3D animation Design</li>
										<li>Info Graphic Design</li>
										<li>Vector Design</li>
									</ul>
								</div>
								<div className="service-thumb">
									<img
                                        src={`${val.imgUrl}`} 
                                        alt={`${val.imgAlt}`}
                                    />
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
        );
    }
}

export default Services;