import React, { Component } from 'react';



class ContactForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactSubject: '',
            contactMassage: '',
        };
    }
    render() {
        return (
            <form>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
                            <input
                                type="text"
                                name="name"
                                id="item01"
                                value={this.state.contactName}
                                onChange={(e)=>{this.setState({contactName: e.target.value});}}
                                placeholder="Your Name *"
                            />
                        </div>
                        <div className="form-group wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
                            <input 
                                type="text"
                                name="email"
                                id="item02"
                                value={this.state.contactEmail}
                                onChange={(e)=>{this.setState({contactEmail: e.target.value});}}
                                placeholder="Your email *" 
                            />
                        </div>
                        <div className="form-group wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                            <input 
                                type="text"
                                name="subject"
                                id="item03"
                                value={this.state.contactSubject}
                                onChange={(e)=>{this.setState({contactSubject: e.target.value});}}
                                placeholder="Write a Subject"
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                            <textarea 
                                rows="4" 
                                type="text"
                                id="item04"
                                name="message"
                                value={this.state.contactMassage}
                                onChange={(e)=>{this.setState({contactMassage: e.target.value});}}
                                placeholder="Your Message"
                            ></textarea>
                        </div>
                        <div className="form-group wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                            <button type="submit" className="btn"><span>Submit Message</span><img src="assets/images/icon/btn-2.png" alt="icon" className="img-fluid" /></button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default ContactForm;






