import React, { Component } from 'react';
import ContactForm from '../ContentData/contactForm';

class Contact extends Component {
    render() {
        return (
            <section className="contact padding-tb text-start" id='Contact'>
                <div className="container">
                    <div className="section-header">
                        <div className="title">
                            <span className="category wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">Hire Me</span>
                            <h2 className="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">I Am Available For Your Next Awesome Project <span>Then Hire Me!</span></h2>
                        </div>
                    </div>
                    <div className="section-wrapper">
                        <div className="maps wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.2272991362356!2d90.3869623154314!3d23.73927259511721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b99cc3c9ec8d%3A0x8e45044745bdba5e!2sWebCode%20Ltd.!5e0!3m2!1sen!2sbd!4v1605519797929!5m2!1sen!2sbd"></iframe>
                        </div>
                        <section className="banner-bottom">
                            <div className="bottom-wrapper">
                                <div className="post-item wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
                                    <div className="post-thumb">
                                        <img src="assets/images/icon/01.png" alt="banner" />
                                    </div>
                                    <div className="post-content">
                                        <h5>Give us a Call</h5>
                                        <h5><span>001-888-123-4567</span></h5>
                                    </div>
                                </div>
                                <div className="post-item wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                                    <div className="post-thumb">
                                        <img src="assets/images/icon/02.png" alt="banner" />
                                    </div>
                                    <div className="post-content">
                                        <h5>Send us a Message</h5>
                                        <h5><span>maxinoadmin@mail.com</span></h5>
                                    </div>
                                </div>
                                <div className="post-item wow fadeInUp" data-wow-duration="1s" data-wow-delay=".6s">
                                    <div className="post-thumb">
                                        <img src="assets/images/icon/03.png" alt="banner" />
                                    </div>
                                    <div className="post-content">
                                        <h5>Visit our Location</h5>
                                        <h5><span>02/07 Suitland Street 120</span></h5>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <ContactForm />
                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;