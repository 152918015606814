import React, { Component } from 'react';

let portfolioList= [
    {
        imgUrl: 'assets/images/portfolio/01.jpg',
        imgalt: 'portfolio thumb',
        portLink: '',
    },
    {
        imgUrl: 'assets/images/portfolio/02.jpg',
        imgalt: 'portfolio thumb',
        portLink: '',
    },
    {
        imgUrl: 'assets/images/portfolio/03.jpg',
        imgalt: 'portfolio thumb',
        portLink: '',
    },
    {
        imgUrl: 'assets/images/portfolio/04.jpg',
        imgalt: 'portfolio thumb',
        portLink: '',
    },
    {
        imgUrl: 'assets/images/portfolio/05.jpg',
        imgalt: 'portfolio thumb',
        portLink: '',
    },
    {
        imgUrl: 'assets/images/portfolio/06.jpg',
        imgalt: 'portfolio thumb',
        portLink: '',
    },
]
class Portfolio extends Component {
    render() {
        return (
            <section className="portfolio padding-tb text-start" id='Portfolio'>
                <div className="container">
                    <div className="portfolio-top">
                        <div className="portfolio-left">
                            <div className="section-header">
                                <div className="title">
                                    <span className="category wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">My Portfolio</span>
                                    <h2 className="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">We Have Done Lot's of <span>My Best Works</span> Lets Check Some of Them</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-wrapper">
                        <div className="grid wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                            {portfolioList.map( (val, i) => (
                                <div className="grid-item g-img" key={i}>
                                    <div className="grid-inner">
                                        <div className="thumb">
                                            <img 
                                                src={`${val.imgUrl}`} 
                                                alt={`${val.imgAlt}`} 
                                            />
                                            {/* <div className="thumb-icon">
                                                <a href={`${val.portLink}`} ><i className="icofont-link"></i></a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Portfolio;

